import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { FiDatabase, FiAperture, FiCloud } from 'react-icons/fi'
import { IoIosCloudOutline } from 'react-icons/io'
import ScrollAnimation from 'react-animate-on-scroll'
import Title from './title'
import * as styles from '../../styles/css/service.module.css'

const Service = () => {
  const { t } = useTranslation();
  const services = [
    {       
        image: <FiDatabase size="90" strokeWidth={1.1}/>,
        link: '/data',
        title: 'Data',
        description: t('servicesData')
    },
    {
        image: <FiAperture size="90" strokeWidth={1.1}/>,
        link: '/vision',
        title: 'Vision',
        description: t('servicesVision')
    },
    {
        image: <IoIosCloudOutline size="90"/>,
        link: '/cloud',
        title: 'Cloud',
        description: t('servicesBilling')
    },
  ]
  return (
    <>
      <div id="service" className="rn-service-area section-gap">
        <div className='container'>
          <div className="row">
            <div className="col-lg-12">
              <Title
                  textAlign = "text-center"
                  radiusRounded = ""
                  subtitle = {t('servicesSubtitle')}
                  title = "Our Services"
                  description = {t('servicesBody')}
                  />
            </div>
          </div>
          <div className="row row--15 service-wrapper">
            {services.map( (service , i) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                <ScrollAnimation 
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}>
                  <div className={`service service__style--2 text-center`}>
                    <div className={`inner ${styles.box}`}>
                      <div className={styles.image}>
                        {service.image}
                      </div>
                      <div className="content">
                        <h4 className="title"><Link to={service.link} dangerouslySetInnerHTML={{__html: service.title}}></Link></h4>
                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: service.description}}></p>
                        <div className={styles.button}>
                        <Link className="btn-read-more" to={service.link}><span>{t('viewMore')}</span></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Service;
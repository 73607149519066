import * as React from 'react'
import ScrollAnimation from "react-animate-on-scroll";
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../layouts/layout'
import Service from '../components/section/service'
import Title from '../components/section/title'
import Breadcrumb from '../components/section/breadcrumb'
import * as styles from '../styles/css/offering.module.css'

const Offering = () => {
  const { t } = useTranslation();
  const topItems = [
    {      
      icon: <object data="/images/icons/acceleration.svg" type="image/svg+xml"></object>,
      title: t('offeringElement1Title'),
      description: t('offeringElement1Body')
    },
    {
      icon: <object data="/images/icons/recognition.svg" type="image/svg+xml"></object>,
      title: t('offeringElement2Title'),
      description: t('offeringElement2Body')
    },   
  ]
  const bottomItems = [
    {
      icon: <object data="/images/icons/big-data.svg" type="image/svg+xml"></object>,
      title: t('offeringElement3Title'),
      description: t('offeringElement3Body')
    },
    {
      icon: <object data="/images/icons/pipelining.svg" type="image/svg+xml"></object>,
      title: t('offeringElement4Title'),
      description: t('offeringElement4Body')
    },
    {
      icon: <object data="/images/icons/dataset.svg" type="image/svg+xml"></object>,
      title: t('offeringElement5Title'),
      description: t('offeringElement5Body')
    },  
  ]
  return (
    <Layout>
      <div className="main-content">  
        <div className="rwt-split-area">
          <div className="row pb--125">
            <Breadcrumb
              title="Offering"
              rootUrl="/"
              parentUrl="Home"
              currentUrl="Offering"
              styles={styles.bg}
            />
          </div>
          <div className="container">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <Title
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = {t('offeringSubtitle')}
                    title = "Our skills"
                    description = {t('offeringBody')}
                  />
                </div>
              </div>
              <div className={`row service-wrapper ${styles.center}`}>
              {topItems.map( (item , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <ScrollAnimation 
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}>
                    <div className={`service service__style--1 icon-circle-style text-center`}>
                      <div className={`icon ${styles.icon}`}>
                        {item.icon}
                      </div>
                      <div className="content">
                        <h4 className="title w-600">{item.title}</h4>
                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: item.description}}></p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                ))}
              </div>
              <div className="row row--15 service-wrapper pb--120">
              {bottomItems.map( (item , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                  <ScrollAnimation 
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}>
                    <div className={`service service__style--1 icon-circle-style text-center`}>
                      <div className={`icon ${styles.icon}`}>
                        {item.icon}
                      </div>
                      <div className="content">
                        <h4 className="title w-600">{item.title}</h4>
                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: item.description}}></p>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
                ))}
              </div>
            </div>
          </div>
          <Service />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Offering
